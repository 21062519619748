<template>
  <div class="row">
    <div class="col-12">
      <v-alert color="cyan" border="left" elevation="2" colored-border>
        <v-form ref="form" v-model="isFormValid" class="w-100">
          <div class="row">
            <div class="col-12 mb-4">
              <div class="card">
                <div class="card-header cyan">
                  <h2 class="text-white">
                    Genel Bilgiler
                    <a @click="showMainInfo = !showMainInfo">
                      <i
                        v-if="showMainInfo"
                        class="fas fa-chevron-down text-white"
                      ></i>
                      <i v-else class="fas fa-chevron-right text-white"></i>
                    </a>
                  </h2>
                </div>
                <div class="card-body" v-show="showMainInfo">
                  <div class="row">
                    <div class="col-6">
                      <v-text-field
                        v-model="name"
                        :counter="255"
                        :rules="name_rules"
                        label="Saha Adı"
                        required
                      ></v-text-field>
                    </div>
                    <div class="col-6">
                      <v-select
                        v-model="selected_power_station.power_station_id"
                        :items="all_power_stations"
                        item-value="power_station_id"
                        item-text="text"
                        label="Güç Santrali Adı"
                        :rules="power_station_id_rules"
                      ></v-select>
                    </div>
                    <div class="col-6">
                      <v-text-field
                        v-model="installed_power"
                        :rules="installed_power_rules"
                        label="Kurulu Güç"
                        required
                      ></v-text-field>
                    </div>
                    <div class="col-6">
                      <v-text-field
                        v-model="area"
                        label="Alan (m2)"
                        required
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <defined-device-form
              :selected_devices="selected_devices_default"
              @changeSelectedDevices="changeSelectedDevices"
            ></defined-device-form>
            <div class="col-12 text-center">
              <v-btn
                :disabled="!isFormValid"
                class="mr-4 bg-success"
                @click="save"
              >
                Kaydet
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-alert>
    </div>
  </div>
</template>

<style scoped>
.card-header {
  padding: 1rem 1.25rem;
}
.fa-chevron-down {
  font-size: 1.45rem;
}
.fa-chevron-right {
  font-size: 1.45rem;
}
</style>

<script>
import { STORE_AREA, UPDATE_AREA } from "@/core/services/store/areas.module";
import { GET_STATIONS } from "@/core/services/store/stations.module";
import DefinedDeviceForm from '../../../custom/device/DefinedDeviceForm.vue';

export default {
  name: "area-form",
  components: { DefinedDeviceForm },
  data() {
    return {
      id: "",
      //Select
      selected_power_station: {
        power_station_id: null,
        text: "Güç Santrali Adı Seçin",
        disabled: true,
      },
      //Form Validation
      isFormValid: false,
      name: "",
      name_rules: [
        (v) => !!v || "Boş bırakılamaz",
        (v) => (v && v.length <= 255) || "Ad 255 karakterden az olmalıdır.",
      ],
      installed_power: "",
      installed_power_rules: [
        (v) => !!v || "Boş bırakılamaz",
        (v) => !isNaN(parseFloat(v)) || "Sayı olmalıdır.",
      ],
      area: "",
      // area_rules: [
      //     v => !!v || "Boş bırakılamaz",
      //     v => (!isNaN(parseFloat(v))) || 'Sayı olmalıdır.',
      // ],
      power_station_id_rules: [(v) => !!v || "Boş bırakılamaz"],
      //Bölümler başlangıçta gözükür gelecekler
      showMainInfo: true,
      selected_devices:[],
      selected_devices_default: [],
      all_power_stations: [],
    };
  },
  methods: {
    save() {
      let id = this.id;
      if (id != "") {
        this.update();
      } else {
        this.new();
      }
    },
    new() {
      let name = this.name;
      let power_station_id = this.selected_power_station.power_station_id;
      let installed_power = this.installed_power;
      let area = this.area;
      let selected_devices = JSON.stringify(this.selected_devices);

      this.$store
        .dispatch(STORE_AREA, {
          name,
          power_station_id,
          installed_power,
          area,
          selected_devices
        })
        .then(() => {
          this.$router.push({ name: "areas" });
        });
    },
    update() {
      let name = this.name;
      let power_station_id = this.selected_power_station.power_station_id;
      let installed_power = this.installed_power;
      let area = this.area;
      let id = this.id;
      let selected_devices = JSON.stringify(this.selected_devices);

      this.$store
        .dispatch(UPDATE_AREA, {
          name,
          power_station_id,
          installed_power,
          area,
          id,
          selected_devices
        })
        .then(() => {
          this.$router.push({ name: "areas.show", params: { id } });
        });
    },
    changeSelectedDevices(devices){
      this.selected_devices = devices;
    }
  },
  created() {
    //Tüm Güç İstasyonlarını Getirme
    this.$store.dispatch(GET_STATIONS).then((res) => {
      res.forEach((el) => {
        this.all_power_stations.push({
          power_station_id: el.id,
          text: el.name,
        });
      });
    });
  },
};
</script>