<template>
  <div>
    <custom-breadcrumb :customBreadcrumb="customBreadcrumb"></custom-breadcrumb>
    <area-form></area-form>
  </div>
</template>

<script>
import customBreadcrumb from "@/view/custom/breadcrumb";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AreaForm from './components/AreaForm.vue';
export default {
  name: "areas.create",
  components: {
    customBreadcrumb,
    AreaForm
  },
  data(){
    return {
      customBreadcrumb: {
        title: "Sahalar",
        type: "Ekle",
        pathName: null,
      },
    };
  },
  computed: {
    ...mapGetters(["breadcrumbs", "pageTitle"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Sahalar", route: "/areas", icon: "flaticon2-layers-1" },
      { title: "Ekle" },
    ]);
  },
};
</script>

<style scoped>
</style>
